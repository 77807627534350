import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "../src/styles/custom.css";
import "../src/styles/banner.css";
import { Accordion } from "react-bootstrap";

function AccordionCss() {
  return (
    <div class="container">
      <Accordion flush style={{ fontSize: 18 }}>
        <Accordion.Item eventKey="1" style={{ fontFamily: "Darker Grotesque" }}>
          <Accordion.Header variant="dark" id="MoreInfo">
            <div
              style={{ fontWeight: 700 }}
              style={{ fontSize: 50 }}
              style={{ width: 150 }}
            >
              More Info!
            </div>
          </Accordion.Header>
          <Accordion.Body
            style={{
              fontFamily: "Darker Grotesque",
            }}
          >
            <h1>How much is a sonnet?</h1>
            <br />

            <p>A sonnet costs 200 MATIC.</p>
            {/* .077 eth */}
            <br />
            <h1>Is each sonnet Minted on Random?</h1>
            <br />

            <p>
              Yes! Each line is chosen via a percentage chance but which sonnet
              is given to you as a result of the mint is determined at random.
            </p>
            <br />

            <h1>Is each sonnet original?</h1>
            <br />

            <p>
              Yes! Although we took inspirations from others poems and fans,
              each poem is 100% original.
            </p>
            <br />

            <h1>
              {" "}
              I love this collection and want to support it, but I want to know
              more. How can we find out more information and ask you more
              questions?
            </h1>
            <br />

            <p>
              Feel free to ask us in our discord, tweet us, DM us on our various
              social media platforms, or simply write send an email to:
            </p>
            <br />

            <h1>How to add the MATIC network?</h1>
            <br />

            <p>
              check out this link:
              https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br />
    </div>
  );
}
export default AccordionCss;
