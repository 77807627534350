// configuration
//start off with basic names of poems

const titles = [
  {
    text: "For Whom ",
    attributes: {
      trait_type: "title",
      value: "for whom",
    },

    value: "for whom",
    weight: 100,
    type: "title",
  },
  {
    text: "The Bell Tolls ",
    attributes: {
      trait_type: "title",
      value: "the bell tolls",
    },

    weight: 80,
    type: "title",
  },
  {
    text: "Ode On A Grecian Urn ",
    attributes: {
      trait_type: "title",
      value: "Ode on a grecian urn ",
    },

    weight: 60,
    type: "title",
  },
  {
    text: "Thanatopsis ",
    attributes: {
      trait_type: "title",
      value: "Thanatopsis ",
    },

    weight: 40,
    type: "title",
  },
  {
    text: "The Raven ",
    attributes: {
      trait_type: "title",
      value: "The Raven ",
    },

    weight: 20,
    type: "title",
  },
];

const lines = [
  {
    text: "On that day my soul grew lightning",
    attributes: {
      trait_type: "line",
      value: "On that day my soul grew lightning",
    },
    weight: 33,
    type: "line",
  },
  {
    text: "It's that upheaval, I muttered",
    attributes: {
      trait_type: "line",
      value: "It's that upheaval, I muttered",
    },
    weight: 66,
    type: "line",
  },
  {
    text: "Beauty and earth are one, but neither has won",
    attributes: {
      trait_type: "line",
      value: "Beauty and earth are one, but neither has won",
    },
    weight: 1,
    type: "line",
  },
  {
    text: "Wherefore though art holds mine breath",
    attributes: {
      trait_type: "line",
      value: "Wherefore though art holds mine breath",
    },
    weight: 33,
    type: "line",
  },
  {
    text: "Butterflies glistening like rainbows",
    attributes: {
      trait_type: "line",
      value: "Butterflies glistening like rainbows",
    },
    weight: 33,
    type: "line",
  },
  {
    text: "On a stormy night",
    attributes: {
      trait_type: "line",
      value: "On a stormy night",
    },
    weight: 33,
    type: "line",
  },
  {
    text: "I love you I exclaimed, brushing your hair away",
    attributes: {
      trait_type: "line",
      value: "I love you I exclaimed, brushing your hair away",
    },
    weight: 33,
    type: "line",
  },
  {
    text: "Torture hath no bounds that I must leave",
    attributes: {
      trait_type: "line",
      value: "Torture hath no bounds that I must leave",
    },
    weight: 33,
    type: "line",
  },
  {
    text: "Seven bells of heaven ring",
    attributes: {
      trait_type: "line",
      value: "Seven bells of heaven ring",
    },
    weight: 33,
    type: "line",
  },
  // "It's that upheaval, I muttered",
  // "Take thy cloudburst from out my heart",
  // "In there stepped a splendid twister",
  // "My weathering, I could not awaken",
  // "Quoth the northwester ",
  // "Mind the bacon!",
  // "Suddenly, I heard some invading",
  // "Suddenly, I heard some invading",
  // "In the dream of summer the old ones hurt",
  // "Amongst the shadows of desire the young ones wander ",
  // "Just the thought of understanding the waves rejoice",
  // "In the streets of summer the old ones seek",
  // "Before the moment of questioning the young ones turn",
  // "In the water of longing the insects sleep",
  // " ",
  // ":)",
  // ":(",
  // "Before the moment of tears the leaves fade",
];
const rarity = [
  { key: "", val: "common", weight: 60, rarity: 1 },
  { key: "_r", val: "rare", weight: 25, rarity: 2 },
  { key: "_sr", val: "super rare", weight: 10, rarity: 3 },
  { key: "_ur", val: "ultra rare", weight: 5, rarity: 4 },
];
module.exports = { titles, lines, rarity };

//export arrays
