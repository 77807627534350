import React, { useEffect, useState } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import axios from "axios";

import * as s from "./styles/globalStyles";
import onClickConnect from "./components/onClickConnect";
import connectFailed from "./components/connectFailed";

import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/styles/custom.css";
import "../src/styles/banner.css";

import InfoBar from "../src/InfoBar";
import Navigation from "../src/navigation";
import RoadMap from "./RoadMap.js";
import AccordionCss from "../src/Accordion";
import Socials from "../src/socials";
import { MenuDynamic } from "./menuDynamic";
import Timer from "./timer";

import poemTest2 from "../src/images/yes2.png";
import poemTest1 from "../src/images/yes1.png";

import Aos from "aos";
import "aos/dist/aos.css";

import setCanvas from "./components/setCanvas";
import addMatic from "./components/addMatic";

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNFT, setClaimingNft] = useState(false); // add usestate and tell user something is loading so they can't do anything else
  const [feedback, setFeedback] = useState("It's your lucky day");
  const [numberClaimed, setNumberClaimed] = useState(0);
  const [attributes, setAttributes] = useState([]);
  const [result, setResult] = useState([]);
  const [displayNumber, setDisplayNumber] = useState(0);

  const claimNFTs = (_amount) => {
    setClaimingNft(true); //set the buttons to loading to show that they're claiming the nfts
    if (blockchain.account != undefined) {
      getNumberClaimed().then((numberClaimed) =>
        processForCanvas(localStorage.getItem("numberclaimed"))
      );
      blockchain.smartContract.methods
        .mint(blockchain.account, _amount)
        .send({
          from: blockchain.account,
          value: blockchain.web3.utils.toWei(
            (0.077 * _amount).toString(),
            "ether"
          ),
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("error");
          setAttributes([]);
          setClaimingNft(false); //says something went wrong and outputs error
          localStorage.clear();
        })
        .then((receipt) => {
          setFeedback("success");
          setClaimingNft(false);
          setAttributes([]);
          localStorage.clear();
        });
    }
  };
  const processForCanvas = (numberClaimed) => {
    var newfile = createLines();
    var CanvasOfFile = setCanvas(newfile);
    ConvertTextToPng(poemTest1, numberClaimed);
  };

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      getNumberClaimed();
      localStorage.clear();
    }
  }, [blockchain.smartContract, dispatch]);
  const { titles, lines } = require("./configcreate.js");
  var weightedRandomObject = require("weighted-random-object");

  const myArgs = process.argv.slice(2);
  const edition = myArgs.length > 0 ? Number(myArgs[0]) : 1;
  const formatLines = [titles, lines, lines, lines, lines, lines, lines, lines];
  const createLines = () => {
    formatLines.forEach((eachFormatLine) => {
      var randomizeLines = weightedRandomObject(eachFormatLine);
      result.push(randomizeLines.text);
      attributes.push(randomizeLines.attributes);
    });
    setAttributes(attributes);
    setResult([result]);
    return result.splice("");
  };
  const getNumberClaimed = async () => {
    try {
      var getNumber = await blockchain.smartContract.methods
        .totalSupply()
        .call();
      setNumberClaimed(getNumber);
      setDisplayNumber(getNumber++);
      localStorage.setItem("numberclaimed", getNumber++);
      return this.state.numberClaimed;
    } catch (err) {
      console.log(err);
    }
  };
  const depositMetadata = async (addedFileLocation) => {
    try {
      const metaDataObj = {
        name: localStorage.getItem("numberclaimed"),
        attributes: attributes,
        image: addedFileLocation,
      };
      var url =
        "https://rf6kk5a3g3.execute-api.us-west-1.amazonaws.com/dev/poemsmeta";

      var body = JSON.stringify(metaDataObj);
      await axios
        .post(url, body)
        .then((jsonFileLocation) =>
          blockchain.smartContract.methods.setBaseURI(jsonFileLocation)
        )
        .catch((err) => console.log("error in upload json to s3", err));
      localStorage.clear();
    } catch (err) {
      console.log(err);
    }
  };
  const ImageToS3 = async (url, jbody4image) => {
    try {
      const sentImage = await axios.post(url, jbody4image);
      if (sentImage !== undefined && sentImage.data !== undefined) {
        depositMetadata(sentImage.data);
      }
      return {
        statusCode: 200,
        body: JSON.stringify(sentImage),
      };
    } catch (e) {
      console.log(e);
      return {
        statusCode: 400,
        body: JSON.stringify(e),
      };
    }
  };

  const ConvertTextToPng = (_newfile, numberClaimed) => {
    var link = document.createElement("a");
    var canvas = document.getElementById("canvas");
    const blobImage = canvas.toBlob(function (_newfile) {
      link.href = URL.createObjectURL(_newfile);
      link.download = link; //downloads file
      canvas.background = "white";
      var imgData = canvas.toDataURL();

      var jbody4image = { name: numberClaimed++, imageArray: imgData };
      var url =
        "https://rf6kk5a3g3.execute-api.us-west-1.amazonaws.com/dev/poems";
      var body = JSON.stringify(jbody4image);

      ImageToS3(url, body);
    }, "image/jpeg");

    link.click();
  };

  useEffect(() => {
    Aos.init({ duration: 2500 });
  });

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const mintDate = new Date(2022, 2, 1, 13, 0, 0, 0);

  return (
    <s.Screen>
      {blockchain.account === "" || blockchain.smartContract === null ? (
        <s.Container data-Aos="fade-up">
          <Navigation />
          <div class="container1">
            <div class="row">
              <br />
              <div class="row">
                {" "}
                <div
                  class="col-xl-6"
                  style={{
                    paddingRight: 20,
                    justifyContent: "center",
                    paddingLeft: 20,
                  }}
                >
                  <div class="subtext1" id="Home">
                    Welcome to Crypto-Poems!
                  </div>
                  <s.SpacerXSmall />
                  <div class="subtext">
                    An NFT collection that will help artists create and sell
                    their written content.
                  </div>
                  <br />
                  <s.SpacerXSmall />
                  <div class="mx-auto">
                    <Button
                      disabled={blockchain.account === undefined}
                      variant="dark"
                      class="mx-auto"
                      // onClick={(e) => {
                      //   if (Date() <= mintDate) {
                      //     e.preventDefault();
                      //     dispatch(connect());
                      //     onClickConnect();
                      //     getNumberClaimed();
                      //     addMatic();
                      //   } else {
                      //     console.log(e);
                      //     dispatch(
                      //       connectFailed(
                      //         "Its too early, ask our discord about getting whitelist access"
                      //       )
                      //     );
                      //   }
                      // }} // add if number claimed <10,001 it works
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        onClickConnect();
                        addMatic();
                      }}
                      style={{ color: "white" }}
                      style={{ fontWeight: 550 }}
                      id="Mint"
                    >
                      Connect to the Blockchain and Mint yours!{" "}
                    </Button>
                  </div>
                  <s.SpacerXSmall />
                  {blockchain.errorMsg !== "" ? (
                    <div style={{ fontWeight: "bold" }}>
                      {blockchain.errorMsg}
                    </div>
                  ) : null}
                  <s.SpacerLarge />
                  <h1 style={{ paddingLeft: 10 }}> Time until Launch:</h1>
                  <Timer />
                </div>
                <br />
                <div class="col-xl-3">
                  <div activeIndex={index} fade onSelect={handleSelect}>
                    <div style={{ paddingLeft: 60, paddingBottom: 40 }}>
                      <img
                        src={poemTest1}
                        class="d-block w-100"
                        class="carousel1"
                        style={{
                          paddingLeft: 20,
                          paddingBottom: 10,
                          paddingRight: 100,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div
                  class="col-xl-3"
                  style={{ paddingRight: 10, borderColor: "black" }}
                >
                  <div activeIndex={index} fade onSelect={handleSelect}>
                    <div style={{ paddingLeft: 60, paddingBottom: 40 }}>
                      <img
                        src={poemTest2}
                        class="d-block w-100"
                        class="carousel1"
                        style={{
                          paddingLeft: 20,
                          paddingBottom: 10,
                          paddingRight: 100,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <InfoBar />
          <RoadMap />
          <AccordionCss />
          {/* <div
            class="mx-auto"
            id="Team"
            style={{ fontFamily: "Darker Grotesque" }}
          >
            Team:
            <br />
            <br />
            William Hashspeare
            <img src={poemTest1} height="300" />
          </div> */}
          <br />
          <Socials />
        </s.Container>
      ) : (
        //add pic of will
        <s.Container
          flex={1}
          ai={"center"}
          style={{ padding: 24 }}
          data-Aos="fade-up"
          class="container1"
          style={{ fontFamily: "Darker Grotesque" }}
        >
          {" "}
          <MenuDynamic />
          <br />
          <div class="row">
            <div class="col" />
            <div
              color="black"
              class="float-left"
              class=".col-xs-1"
              style={{ fontWeight: 700 }}
            >
              Your account: {blockchain.account}
            </div>
            <br />
          </div>{" "}
          <br />
          <s.TextDescription
            style={{ textAlign: "center" }}
            style={{ fontWeight: 600 }}
          >
            {" "}
            {feedback}
          </s.TextDescription>
          <br></br>
          <s.TextTitle style={{ fontWeight: 700 }}>
            {" "}
            {displayNumber}/ 10,000 have been claimed!
          </s.TextTitle>
          <s.SpacerSmall />
          <br></br>
          <Button
            variant="success"
            disabled={claimingNFT ? 1 : 0}
            onClick={(e) => {
              if (blockchain.account != undefined) {
                e.preventDefault();
                claimNFTs(1);
              } else {
                dispatch(
                  connectFailed(
                    "Please log in, metamask can't find the account"
                  )
                );
              }
            }}
            style={{ fontWeight: 500 }}
          >
            {claimingNFT
              ? "busy claiming, don't reload or your Metadata could be deleted!"
              : "Claim your NFT!"}
          </Button>
          <s.SpacerSmall />
          <div class="mx-auto">
            <canvas
              id="canvas"
              style={{ textAlign: "center" }}
              width="1000"
              height="1000"
            />
          </div>
          <br />
        </s.Container>
      )}
    </s.Screen>
  );
}
export default App;
