import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/styles/custom.css";
import "../src/styles/banner.css";
function InfoBar() {
  return (
    <div
      data-Aos="fade-right"
      style={{ marginBottom: 0 }}
      style={{ background: "black" }}
    >
      <div class="row" style={{ color: "white" }}>
        <div class="col-sm-4" style={{ padding: 40 }}>
          <h1 class="cardstitle" style={{ justifyContent: "center" }}>
            {" "}
            What's Crypto-Poems?
          </h1>
          <br />
          <p class="cardsfont">
            Crypto-Poems is a collection of 10,000 generated poem NFTs. The
            poems are ERC-721 tokens on the MATIC network. Each poem is uniquely
            generated from a collection of over 100 unique lines and titles.{" "}
          </p>
        </div>

        <div
          class="col-sm-4"
          style={{ color: "white" }}
          style={{ padding: 40 }}
        >
          <h1 class="cardstitle" style={{ justifyContent: "center" }}>
            {" "}
            What's our mission?
          </h1>
          <br />

          <p class="cardsfont">
            To help written content creators receive fair ownership, access to
            passive income through royalties, and compensation through an NFT
            marketplace for written works.
          </p>
        </div>

        <div class="col-sm-4" style={{ padding: 40 }}>
          <h1 class="cardstitle" style={{ justifyContent: "center" }}>
            {" "}
            What're our goals?
          </h1>
          <br />
          <p class="cardsfont">
            As more people join us in our goal we will accomplish our goals of
            creating an NFT minter, a liquidity pool, and an NFT marketplace for
            written works.
          </p>
        </div>
      </div>
    </div>
  );
}
export default InfoBar;
