import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "../src/styles/custom.css";
import "../src/styles/banner.css";
export function MenuDynamic() {
  return (
    <div class="navigation">
      <nav
        class="navbar navbar-expand"
        style={{
          padding: 10,
          background: "black",
          color: "white",
        }}
      >
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a
              class="navbar-brand"
              href=""
              style={{ fontFamily: "Darker Grotesque", color: "white" }}
            >
              Crypto-Poems
            </a>
          </li>
          <li class="nav-item active">
            <a
              class="navbar-brand"
              href=""
              style={{ fontFamily: "Darker Grotesque", color: "white" }}
            >
              Home
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
