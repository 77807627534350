// constants
import Web3 from "web3";
import FlattenedSmartContract from "../../contracts/FlattenedSmartContract.json"; //verify contract and when verified copy abi in flattenedsmartcontract file
// log
import onClickConnect from "../../components/onClickConnect";
import { fetchData } from "../data/dataActions";
import switchToMatic from "../../components/switchToMatic";
import addMatic from "../../components/addMatic";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const { ethereum } = window;
    const metaMaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metaMaskIsInstalled) {
      let web3 = new Web3(window.ethereum);
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await window.ethereum.request({
          method: "net_version",
        });
        // const NetworkData = await FlattenedSmartContract.networks[networkId]; //comment out
        if (networkId == 80001) {
          //replace if networkId== 137 for mainnet 80001 for mumbai
          const SmartContractObj = new web3.eth.Contract(
            FlattenedSmartContract,
            "0x3c3095Aa18fa161D481CC6551a20eF663045f44e" //replace with real contracts address and networkId w/ 137 also import real contracts abi
            //
            //
            //!!!!!!!!!!!!!!!! MAKE SURE TO DEPLOY ON A NON IMPORTED ACCOUNT IE ACCOUNT 8
            //
            //
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          window.ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          window.ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          // Add listeners end
        } else {
          if (networkId != 80001) {
            addMatic();
          }
          // dispatch(
          //   connectFailed(
          //     "Change network to Polygon/Matic.                " +
          //       "If you don't have the MATIC network, add it by following this guide:   https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/ "
          //   )
          // );
        }
      } catch (err) {
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Install Metamask."));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};

export const viewMetadata = () => {};
