import React, { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "../src/styles/custom.css";
import "../src/styles/banner.css";

export default function Timer() {
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const timer = () => {
    const countDate = new Date(2022, 2, 1, 13, 0, 0, 0).getTime();
    const now = new Date().getTime();
    const diff = countDate - now;
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const displayDay = Math.floor(diff / day);
    const displayHour = Math.floor((diff % day) / hour);
    const displayMinute = Math.floor((diff % hour) / minute);
    const displaySecond = Math.floor((diff % minute) / second);
    setDay(displayDay);
    setHour(displayHour);
    setMinute(displayMinute);
    setSecond(displaySecond);
  };
  setInterval(timer, 1000);

  return (
    <div>
      <div class="countdown">
        <div class="container-day">
          <h1>Days: </h1>
          <div class="day">{day}</div>
        </div>
        <div class="container-hour">
          <h1>Hours: </h1>
          <div class="hour">{hour}</div>
        </div>
        <div class="container-minute">
          <h1>Minutes: </h1>
          <div class="minute">{minute}</div>
        </div>
        <div class="container-second">
          <h1>Seconds: </h1>
          <div class="second ">{second}</div>
        </div>
        <timer style={{ padding: 10 }} />
      </div>
    </div>
  );
}
