import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/styles/custom.css";
import "../src/styles/banner.css";
import React from "react";
function Socials() {
  return (
    <div class="mx-auto">
      <br />
      <div class="mx-auto" id="socials">
        <h1 style={{ fontFamily: "Darker Grotesque", fontSize: 18 }}>
          Learn More:{" "}
        </h1>
        <br />
        <a href="https://discord.gg/MtqDm2SP7S" className=" discord">
          <FontAwesomeIcon icon={faDiscord} size="2x">
            {" "}
            <a />{" "}
          </FontAwesomeIcon>
        </a>
        <a href="https://twitter.com/Crypto__Poems" className="twitter">
          <FontAwesomeIcon icon={faTwitter} size="2x">
            {" "}
            <a />{" "}
          </FontAwesomeIcon>
        </a>
      </div>
      <br />
    </div>
  );
}
export default Socials;
