const setCanvas = (lines) => {
  var canvas = document.getElementById("canvas");

  console.log("_newfile", lines);

  var ctx = canvas.getContext("2d");
  var cw = (canvas.width = 800);
  var ch = (canvas.height = 800);
  ctx.font = "18px Darker Grotesque";
  ctx.fontWeight = 500;
  ctx.textAlign = "left";
  ctx.textBaseline = "left";
  ctx.strokeStyle = "black";
  ctx.fillStyle = "black";
  ctx.lineWidth = 1;
  ctx.background = "white";
  ctx.border = 10;
  ctx.translate(0, 50);

  for (let i = 0; i < lines.length; i++) {
    ctx.fillText(lines[i], 0, i * 50);
  }

  ///////////////text as image stuff/////////////////

  var textAsImage = new Image();
  textAsImage.onload = function () {
    document.body.appendChild(textAsImage);
  };
  textAsImage.src = canvas.toDataURL();

  return textAsImage;
}; // import from different file
export default setCanvas;
