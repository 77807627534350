import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/styles/custom.css";
import "../src/styles/banner.css";
//add stuff on sides of col with writer compensation #s
function RoadMap() {
  return (
    <div class="mx-auto">
      <div class="container">
        <div class="row">
          <div
            class="col-3"
            class="container1"
            data-Aos="fade-right"
            style={{ textAlign: "center" }}
            id="Roadmap"
          >
            {" "}
            This roadmap outlines our goals and where we want to take
            Crypto-Poems. There are many exciting ideas which our team plans to
            implement in the future. It may evolve over time and hopefully will
            improve!
          </div>
        </div>
      </div>
      <br></br>
      <div class="row" class="mx-auto">
        <div>
          <div class="container">
            <div class="timeline">
              <ul>
                <li>
                  {" "}
                  <div class="timeline-content" data-Aos="fade-up">
                    <h1>Upon 10% minted</h1>
                    <p>
                      {" "}
                      Comission a poetry book of all 1000 sonnets minted which
                      we will distribute to 5 random owners & list Crypto-Poems
                      on rarity.tools
                    </p>
                  </div>
                </li>
                <li>
                  {" "}
                  <div class="timeline-content" data-Aos="fade-up">
                    <h1>Upon 25% minted</h1>
                    <p>
                      {" "}
                      Create a collaborative poetry board where users can create
                      their own free poems as NFTs in the Metaverse
                    </p>
                  </div>
                </li>
                <li>
                  <div class="timeline-content" data-Aos="fade-up">
                    {" "}
                    <h1>Upon 50% minted</h1>
                    <p>
                      {" "}
                      A book of all sonnets becomes available for sale as an
                      ebook, the proceeds will go towards the DAO and future
                      projects
                    </p>
                  </div>
                </li>
                <li>
                  {/* maybe swap 25% and 50% */}
                  <div class="timeline-content" data-Aos="fade-up">
                    {" "}
                    <h1>Upon 75% minted</h1>
                    {/* https://www.youtube.com/watch?v=BK5ypcAFA8A  and create dao on aragon*/}
                    <p>
                      {" "}
                      Add a liquidity pool for NFTs and submit proposals to the
                      DAO regarding the upcoming NFT marketplace.
                    </p>
                  </div>
                </li>
                {/* maybe arrange an artist meetup/art meetups  */}
                <li>
                  <div class="timeline-content" data-Aos="fade-up">
                    {" "}
                    <h1>Upon 100% minted</h1>
                    <p>
                      {" "}
                      Full nft marketplace for written content will be created
                      and holders of Poems will be whitelisted and have early
                      access to a collection of written works that we will
                      comission from popular poets which the DAO will vote on.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-3"></div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}
export default RoadMap;
