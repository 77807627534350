const addMatic = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x13881" }],
    });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x13881",

              chainName: "Mumbai testnet",

              nativeCurrency: {
                name: "Polygon",

                symbol: "MATIC",

                decimals: 18,
              },
              rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],

              blockExplorerUrls: ["https://polygonscan.com/"],
            },
          ],
        });
      } catch (addError) {
        console.log("error", addError);
      }
    }
    // handle other "switch" errors
  }
};

export default addMatic;
