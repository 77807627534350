import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../src/styles/custom.css";
// import "../src/styles/banner.css";

function Navigation() {
  return (
    <div class="navigation">
      <nav
        class="navbar navbar-expand"
        style={{
          paddingLeft: 10,

          background: "black",
          color: "white",
        }}
      >
        <a
          class="navbar-brand"
          href=""
          style={{ fontFamily: "Darker Grotesque", color: "white" }}
        >
          Crypto-Poems{" "}
        </a>
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a
              class="nav-link"
              c
              href=""
              style={{ fontFamily: "Darker Grotesque", color: "white" }}
            >
              Home
            </a>
          </li>
          <li class="nav-item active">
            <a
              class="nav-link"
              c
              href="#Mint"
              style={{ fontFamily: "Darker Grotesque", color: "white" }}
            >
              Mint
            </a>
          </li>
          <li class="nav-item active">
            <a
              class="nav-link"
              href="#Roadmap"
              style={{ fontFamily: "Darker Grotesque", color: "white" }}
            >
              Roadmap
            </a>
          </li>

          <li class="nav-item active">
            <a
              class="nav-link"
              href="#MoreInfo"
              style={{ fontFamily: "Darker Grotesque", color: "white" }}
            >
              More Info!
            </a>
          </li>
          <li class="nav-item active">
            <a
              class="nav-link"
              href="#Team"
              style={{ fontFamily: "Darker Grotesque", color: "white" }}
            >
              Team
              {/* <  span class="sr-only"></span> */}
            </a>
          </li>
          <li class="nav-item active">
            <a
              class="nav-link"
              href="#socials"
              style={{ fontFamily: "Darker Grotesque", color: "white" }}
            >
              Connect with us!
              {/* <  span class="sr-only"></span> */}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
export default Navigation;
